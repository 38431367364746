body {
	font-family: Arial, sans-serif;
	margin: 0;
	padding: 0;
	background-color: #f7f5f3;
}

a {
	text-decoration: none;
	color: inherit;
}

div {
	box-sizing: border-box;
}
fieldset:-internal-autofill-selected {
	background-color: transparent !important;
}
input:-internal-autofill-selected {
	background-color: transparent !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
}
